import classNames from "classnames";
import {get} from "lodash";
import React from "react";
import {Link} from "react-router-dom";
import {logoutAct, clearPayerUser} from "../../Actions/authActions";
import {
   HomeIcon,
    ProfileIcon, FormIcon,
} from "../../Components/Icons";


const UserHeader = ({themeColor1, subdomain, dispatch, selectedTab, setSelectedTab, logoName}) => {

    const onLogout = (e) => {
        e.preventDefault();
        dispatch(logoutAct(true, subdomain));
    };


    return (
        <>
            <div>
                <div className="w-100 justify-content-between header-card position-relative row m-0">
                    <div className="logo logo-width col-lg-3 col-md-12 col-sm-12 col-xs-12">
                        <img
                            src={logoName || "/images/logo.png"}
                            className="img-fluid"
                            alt="Logo"
                        />
                    </div>

                    <div className="col-lg-7  intakeform-nav">
                        <ul
                            className=" nav  nav-list d-sm-flex justify-content-center"
                            id="pills-tab"
                            role="tablist"
                        >
                            <li
                                className="home-icon nav-list-item cursor-pointer"
                                role="presentation"
                                onClick={() => {
                                    setSelectedTab("dashboard");
                                }}
                            >
                                <a
                                    className={`nav-link header-icon text-md-center  ${
                                        selectedTab === "dashboard" ? "nav-list-selected" : ""
                                    }`}
                                    id="pills-home-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-home"
                                    role="tab"
                                    aria-controls="pills-home"
                                    aria-selected="true"
                                >
                                    <div className="home">
                                        <HomeIcon color={themeColor1}/>
                                    </div>
                                    <div className="text-dark d-none d-md-block">Home</div>
                                </a>
                            </li>


                            <li
                                className="nav-list-item cursor-pointer"
                                role="presentation"
                                onClick={() => {
                                    setSelectedTab("forms");
                                }}
                            >
                                <a
                                    className={`nav-link header-icon text-center ${
                                        selectedTab === "forms" ? "nav-list-selected" : ""
                                    }`}
                                    id="pills-home-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-home"
                                    role="tab"
                                    aria-controls="pills-home"
                                    aria-selected="true"
                                >
                                    <div className="office">
                                        <FormIcon color={themeColor1}/>
                                    </div>
                                    <div className="text-dark d-none d-md-block">
                                        Forms
                                    </div>
                                </a>
                            </li>

                        </ul>

                    </div>

                    <div className="header-admin  col-lg-2 col-md-12 col-sm-12 ">
                        <div className="d-flex flex-end flex-wrap header-admin-email flex-column">
                            <span><ProfileIcon fill={'#212529'}/></span>
                            <Link
                                to="#"
                                className="text-logout message-received-text"
                                onClick={(e) => onLogout(e)}
                            >
                                Log out
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserHeader;
