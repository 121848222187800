import {executePost} from "../Api";
import moment from "moment";

export class ManualPayment {

    timeout = null;
    data;
    token = null;
    ssl_transaction_type;

    resolve;
    reject;

    constructor(data) {
        this.data = data;
    }

    async init() {
    }

    initTransactionType() {
        if (this.data.isEcheck) {
            const isAddPaymentMethod = this.data.amount == 0 || (this.data.isPaymentSaveDetails && this.data.amount > 0);
            if (isAddPaymentMethod) {
                this.ssl_transaction_type = "ecsaddrecurring";
                this.ssl_next_payment_date = moment().add(1, 'Y').format("MM/DD/YYYY");
                this.ssl_billing_cycle = "SUSPENDED";
            } else {
                this.ssl_transaction_type = "ecspurchase";
            }
        } else {
            if (this.data.amount == 0 || this.data.amount == 0.01) {
                this.ssl_transaction_type = "ccauthonly"
            } else {
                this.ssl_transaction_type = "ccsale"
            }
        }
    }

    pay(e) {
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        return new Promise(async (resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
            if (this.data.isEcheck && this.data.ssl_recurring_id) {
                const result = await this.chargeECheckPaymentMethod(this.data.ssl_recurring_id);
                this.resolve(result);
            } else {
                this.initTransactionType();
                this.token = await this.retrieveToken();
                await this.sendToConverge();
            }
        });
    }

    retrieveToken() {
        return executePost("payments/token", {
            ssl_amount: this.getAmount(),
            subdomain: this.data.subdomain,
            ssl_transaction_type: this.ssl_transaction_type,
        }).then((res) => {
            const {data} = res || {};
            return data?.data;
        }).catch((err) => {
            console.log("err pwo3zqvb", err);
            return err;
        });
    }

    chargeECheckPaymentMethod(ssl_recurring_id) {
        return executePost("transaction/updateAndChargeECheck", {
            adminUser: this.data.adminUser,
            payerUser: this.data.payerUser,
            paymentMethod: {
                isDefault: this.data.isDefault,
                paymentType: "eCheck"
            },
            patientPayer: this.data.patientPayer,
            ...this.serializeDataForConverge(),
            ssl_recurring_id: ssl_recurring_id,
            bankName: this.data.bankName
        });
    }

    getAmount() {
        // return +this.data.amount === 0 ? "0.01" : this.data.amount;
        let ssl_amount = +this.data.amount === 0 ? "0.01" : this.data.amount;
        if (typeof ssl_amount === 'number') {
            ssl_amount = ssl_amount.toString();
        }
        if (typeof ssl_amount !== 'string') {
            ssl_amount = "0.01";
        }
        ssl_amount = ssl_amount.replaceAll(',','');
        console.log("ssl_amount", ssl_amount);
        return ssl_amount;

    }

    serializeDataForConverge() {

        let request;
        if (this.data.isEcheck) {
            // case 1:
            // User wants to make a payment but not add eCheck
            // -- front end does ssl_transaction_type = "ecspurchase"

            //case 2:
            // User wants to add eCheck as a payment method but make no payment
            // -- front end does ssl_transaction_type = "ecsaddrecurring"

            // case 3 = case 2 + case 4:
            // User wants to make a payment AND add eCheck
            // -- front end does ecsaddrecurring

            // -- back end does ecsupdaterecurring and then ecsrecurringsale

            // case 4:
            // User wants to make a payment using an eCheck payment method that's already saved
            // -- back end does ecsupdaterecurring and then ecsrecurringsale
            // this is already in the back end, in converge.js

            /*
            ssl_company: companyName,
             */


            // ssl_amount: isAddPaymentMethod ? "0.01" : this.getAmount(),
            request = {
                ssl_amount: this.getAmount(),
                ssl_bank_account_type: this.data.bankAccountType,
                ssl_transaction_type: this.ssl_transaction_type,
                ssl_next_payment_date: this.ssl_next_payment_date,
                ssl_billing_cycle: this.ssl_billing_cycle,
                ssl_txn_auth_token: this.token,
                ssl_first_name: this.data.firstName,
                ssl_last_name: this.data.lastName,
                ssl_company: this.data.companyName,
                ssl_email: this.data.payerEmail,
                ssl_aba_number: this.data.bankRoutingNumber,
                ssl_bank_account_number: this.data.bankAccountNumber,
                ssl_agree: "1",
                ssl_recurring_id: this.data.ssl_recurring_id
            };
        } else {

            request = {
                ssl_amount: this.getAmount(),
                ssl_transaction_type: this.ssl_transaction_type,
                ssl_txn_auth_token: this.token,
                ssl_exp_date: this.data.expirationDate,
                ssl_cvv2cvc2: this.data.cvv,
                ssl_card_number: this.data.cardNumber,
                ssl_first_name: this.data.firstName,
                ssl_last_name: this.data.lastName,
                ssl_email: this.data.payerEmail,
                ssl_get_token: "Y",
                ssl_add_token: "Y",
            };
        }
        if (!this.data.cvv && this.data.ssl_token) {
            request.ssl_token = this.data.ssl_token;
        }
        return request;
    }

    async sendToConverge() {
        const callback = {
            onError: (error) => {
                console.error(error);
                this.reject(error);

            }, onDeclined: (response) => {
                console.error(response);
                this.reject(response);
            },
            onDCCDecision: () => {
                window.ConvergeEmbeddedPayment.dccDecision({dccOption: false}, callback);
            }, onApproval: async (response) => {
                try {
                    //we must get rid of these!
                    const ssl_card_number = response?.ssl_card_number?.substr(-4) || "";
                    const transactionId = response?.ssl_txn_id || ""
                    const ssl_card_short_description = response?.ssl_card_short_description || "";
                    const ssl_token = response?.ssl_token || "";
                    if (this.data.isEcheck && this.data.isPaymentSaveDetails && this.data.amount > 0.01) {
                        const ssl_recurring_id = response?.ssl_recurring_id || "";
                        const result = await this.chargeECheckPaymentMethod(ssl_recurring_id);
                        this.resolve(result);
                    } else {
                        this.resolve({
                            ssl_card_number,
                            transactionId,
                            ssl_token,
                            ssl_card_short_description,
                            response,
                            bankName: this.data.bankName,
                            ...response,
                            // data: this.data
                        });
                    }


                } catch (err) {
                    console.error(err);
                    this.reject(err);
                }
            },
        };
        await window.ConvergeEmbeddedPayment.pay(this.serializeDataForConverge(), callback);
    }

}
