// import { Popover } from "bootstrap";
import React from "react";
import { Overlay, Popover } from "react-bootstrap";
import "./popover.css";

const CustomPopoverHelloSign = ({
  title = "",
  show,
  target,
  onSign,
  close,
  setShowPopUp,
  onSignIntakeForm,
}) => {
  return (
    <>
      <div>
        <Overlay
          show={show}
          target={target}
          placement="bottom"
          trigger="click"
          rootClose
          onHide={() => setShowPopUp(!show)}
        >
          <Popover id="doc" className="mt-2 customHeloSign">
            <Popover.Title className="bg-white border-bottom-0">
              {title}
            </Popover.Title>
            <Popover.Content className="popover-button">
              <button
                className="custom-popover-button close-btn sos-custom-btn-css"
                onClick={close}
              >
                Cancel
              </button>
              <button
                className="custom-popover-button sos-custom-btn-css"
                onClick={(e) => onSign(e, onSignIntakeForm)}
              >
                Re-sign
              </button>
            </Popover.Content>
          </Popover>
        </Overlay>
      </div>
    </>
  );
};

export default CustomPopoverHelloSign;
