import React from "react";
import { Modal, Table } from "react-bootstrap";
import "./payer-list-css.css";
import { CrossIcon } from "../Icons";
import map from "lodash/map";

const CustomPayerListPopup = (props) => {
  const {
    openModal,
    onCloseModal,
    selectPayerUser,
    payerList,
  } = props;

  const closeModal = () => {
    onCloseModal(!openModal);
  };

  const handleOnclick = (e, payerUser) => {
    e.preventDefault();
    selectPayerUser(payerUser);
    setTimeout(()=>{
        closeModal();
    }, 500);
  };

  return (
    <Modal show={openModal} size="lg" centered className="reply-msg-content" size="lg" 
    style={{maxWidth: '700px', width: '100%', position:'absolute', left:'20%'
    }}>
      <div onClick={() => closeModal()} className="close-modal-btn">
        <CrossIcon />
      </div>
      <Modal.Body >
        <div className="text-center mt-8 ">
          
            {payerList.length > 0 ? <h3>Choose a Payer</h3> : ''}
          
          <div className="transaction-details">
                            <>
                              {payerList.length > 0 ? (
                                <>                                
                                  <Table striped>
                                    <thead>
                                      <th>Payer ID</th>
                                      <th>First Name</th>
                                      <th>Last Name</th>
                                      <th>Patients</th>
                                    </thead>
                                    <tbody>
                                    {map(payerList, (i, index) =>
                                        <tr className = "payer-list-table" key={index}  onClick={(e) => handleOnclick(e, i)} style={{cursor:'pointer'}}>
                                            <td scope="row">{i.payerId}</td>
                                            <td>{i.payerFirst}</td>
                                            <td>{i.payerLast}</td>
                                            <td>
                                            {i.patientPayer.length > 0 ? (
                                              
                                                  <Table striped>
                                                        <thead>
                                                        <th>Patient ID</th>
                                                          <th>Patient Name</th>
                                                        </thead>
                                                        <tbody>
                                                        {map(i.patientPayer, (j, ind) =>
                                                            <tr key={ind} >
                                                                <td scope="row">{j.patientNumber}</td>
                                                                <td>{j.patientFirst && j.patientLast ? `${j.patientFirst} ${j.patientLast}` : `${j.patientName}`}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                  </Table>
                                            ) :''}
                                            </td>
                                        </tr>
                               )}
                                 </tbody>
                              </Table>
                                </>
                              ) : (
                                <div className="text-center no-transaction-yet">
                                  No Payer Matched
                                </div>
                              )}
                            </>
                          </div>

          
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CustomPayerListPopup;
